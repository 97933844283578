// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./paDvwHBTo-0.js";
import Caret from "./tzgFFTUbC";
const CaretFonts = getFonts(Caret);

const cycleOrder = ["GSBG8FC6P", "MtK8AzLHv"];

const serializationHash = "framer-DRlZd"

const variantClassNames = {GSBG8FC6P: "framer-v-1gbus9w", MtK8AzLHv: "framer-v-9qvymx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {LMCymGpQ1: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "GSBG8FC6P", Open: "MtK8AzLHv"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "Soluções financeiras", PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "GSBG8FC6P"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "GSBG8FC6P", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1nzhzpa = activeVariantCallback(async (...args) => {
if (PWSlWeqS6) {
const res = await PWSlWeqS6(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1gbus9w", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"GSBG8FC6P"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1nzhzpa} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({MtK8AzLHv: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Product</motion.p></React.Fragment>} className={"framer-1wcu7et"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"H0CvuK66k"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={DApvjrdfG} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-11ejcft-container"} layoutDependency={layoutDependency} layoutId={"U06MJBk6q-container"}><Caret height={"100%"} id={"U06MJBk6q"} layoutId={"U06MJBk6q"} variant={"Hi1vEtS1B"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DRlZd.framer-ug4e26, .framer-DRlZd .framer-ug4e26 { display: block; }", ".framer-DRlZd.framer-1gbus9w { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; width: min-content; }", ".framer-DRlZd .framer-1wcu7et { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-DRlZd .framer-11ejcft-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DRlZd.framer-1gbus9w { gap: 0px; } .framer-DRlZd.framer-1gbus9w > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-DRlZd.framer-1gbus9w > :first-child { margin-left: 0px; } .framer-DRlZd.framer-1gbus9w > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 165
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"MtK8AzLHv":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","DApvjrdfG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpaDvwHBTo: React.ComponentType<Props> = withCSS(Component, css, "framer-DRlZd") as typeof Component;
export default FramerpaDvwHBTo;

FramerpaDvwHBTo.displayName = "Trigger";

FramerpaDvwHBTo.defaultProps = {height: 40, width: 165};

addPropertyControls(FramerpaDvwHBTo, {variant: {options: ["GSBG8FC6P", "MtK8AzLHv"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, DApvjrdfG: {defaultValue: "Soluções financeiras", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerpaDvwHBTo, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2", weight: "500"}]}, ...CaretFonts], {supportsExplicitInterCodegen: true})